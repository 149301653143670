/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Badge } from "primereact/badge";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import i18n from "./plugins/i18n";

/*Service*/
import { NotificationGetNotReading } from "./service/notification";
// import { WhatsappNotificationGetCustomerMessage } from './service/whatsapp';
import { CustomersPhoneGet } from "./service/customer";
import { lngs } from "./constants/string";

import { JwtHelper } from "./plugins/jwtHelper";

export const AppTopbar = (props) => {
  const { t } = useTranslation();
  const profileMenu = useRef(null);
  const toast = useRef(null);
  const profileItems = [
    {
      label: "Hesabım",
      items: [
        {
          label: "Çıkış",
          icon: "pi pi-upload",
          command: () => {
            localStorage.setItem("token", "");
            toast.current.show({
              severity: "success",
              summary: "Başarılı",
              detail: "Başarılı şekilde çıkış yapıldı",
              life: 3000,
            });
            setTimeout(() => {
              window.location.reload();
              window.location.hash = "/giris";
            }, 1000);
          },
        },
      ],
    },
  ];

  const [notificationLength, setNotificationLength] = useState(0); //Son 5 dakika kala bildirim atıyor her saatin 10-20-30'unda gidiyor
  const [whatsappNotification, setWhatsappNotification] = useState(0);
  const [customerSearchData] = useState();
  const [customerSearchList, setCustomerSearchList] = useState({});

  useEffect(() => {
    NotificationGetNotReading(JwtHelper.getUserId()).then((res) => {
      setNotificationLength(res.length);
    });
    // WhatsappNotificationGetCustomerMessage(JwtHelper.getUserId()).then(res => {
    //     console.log("whatsapp notification",res)
    //     setWhatsappNotification(res)
    // })
  }, []);

  const customerGetPhoneList = (e) => {
    if (e.target.value.length === 10) {
      CustomersPhoneGet(e.target.value).then((res) => {
        console.log("müşteri numarası ile cevap geldi", res);
        if (res.length === 0) {
          toast.current.show({
            severity: "info",
            summary: "Bilgilendirme",
            detail: "Müşteri Mevcut Değil",
            life: 3000,
          });
        }
        setCustomerSearchList(res);
      });
    }
    if (customerSearchList.length > 0 && e.target.value.length < 10) {
      setCustomerSearchList({});
    }
  };
  return (
    <div className="layout-topbar">
      <Toast ref={toast}></Toast>
      <Link to="/" className="layout-topbar-logo">
        <img
          width={250}
          height={130}
          src={
            props.layoutColorMode === "light"
              ? "assets/layout/images/logo.svg"
              : "assets/layout/images/logo-white.svg"
          }
          alt="logo"
        />
      </Link>

      <button
        type="button"
        className="p-link  layout-menu-button layout-topbar-button"
        onClick={props.onToggleMenuClick}
      >
        <i className="pi pi-bars" />
      </button>

      <div
        className="top-search"
        style={{ width: "40%", marginLeft: "10%", position: "relative" }}
      >
        <span className="p-input-icon-left" style={{ width: "100%" }}>
          <i className="pi pi-search" />
          <InputText
            style={{
              width: "100%",
            }}
            placeholder={t("global.headerInputSearch")}
            type="text"
            value={customerSearchData}
            onChange={(e) => customerGetPhoneList(e)}
          />
        </span>
        {/* <small>Lütfen numaranın başında 0 olmadan 10 karakterin tamamınıda giriniz</small> */}
        <div
          style={{
            position: "absolute",
            backgroundColor: "#fff",
            padding: 15,
            width: "100%",
            top: "33px",
            zIndex: 3,
            boxShadow: "0px 0px 1px 0px #333",
          }}
          className={customerSearchList.length > 0 ? " " : "d-none"}
        >
          <div>
            <small style={{ marginBottom: 10, display: "inline-block" }}>
              Girmiş olduğunuz numara aşağıda ki müşteriye aittir. Aşağıda ki
              müşterinin sorumlu personeli{" "}
              <b>{customerSearchList[0]?.user.name}</b>'dir.
            </small>
            <div style={{ fontSize: "14px", marginBottom: 10 }}>
              <b>Müşterinin Adı:</b> {customerSearchList[0]?.name}
            </div>
            <div style={{ fontSize: "14px", marginBottom: 10 }}>
              <b>Müşterinin Sorumlu Personeli:</b>{" "}
              {customerSearchList[0]?.user.name}
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="p-link layout-topbar-menu-button layout-topbar-button"
        onClick={props.onMobileTopbarMenuClick}
      >
        <i className="pi pi-ellipsis-v" />
      </button>

      <ul
        className={classNames("layout-topbar-menu lg:flex origin-top", {
          "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive,
        })}
      >
        <li>
          {Object.keys(lngs).map((lng) => (
            <button
              style={
                i18n.resolvedLanguage === lng
                  ? { display: "none" }
                  : { display: "block" }
              }
              className="p-link login-lang-img-link"
              key={lng}
              onClick={() => i18n.changeLanguage(lng)}
              disabled={i18n.resolvedLanguage === lng}
            >
              {lng === "tr" ? (
                <img
                  style={{ width: 25, margin: 0 }}
                  src={
                    "https://cleandye.com/wp-content/uploads/2020/01/English-icon.png"
                  }
                />
              ) : (
                <img
                  style={{ width: 25, margin: 0 }}
                  src={
                    "https://cdn-icons-png.flaticon.com/512/3909/3909414.png"
                  }
                />
              )}
            </button>
          ))}
        </li>
        <li>
          <Link to="/hatirlaticilarim" className="p-link layout-topbar-button">
            <i className="pi pi-bell p-text-secondary p-overlay-badge">
              {notificationLength > 0 && (
                <Badge
                  value={notificationLength}
                  severity="danger"
                  style={{ display: "inline-block" }}
                />
              )}
            </i>
            <span>Bildirimler</span>
          </Link>
        </li>
        <li>
          <Menu model={profileItems} popup ref={profileMenu} id="popup_menu" />
          <button
            className="p-link layout-topbar-button"
            onClick={(event) => profileMenu.current.toggle(event)}
            aria-controls="popup_menu"
            aria-haspopup
          >
            <i className="pi pi-user" />
          </button>
        </li>
      </ul>
    </div>
  );
};
